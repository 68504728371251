<template>
  <div class="appHomePage-warper" id="pageWarper">
    <div class="home-page-boxers">
      <div class="home-page-header flexbox" ref="headerRef">
        <div class="header-left flexbox">
          <div
            class="user-avatar imgFlex"
            v-lazy-container="{ selector: 'img' }"
          >
            <img
              :data-error="require('../../assets/shop-default.png')"
              :data-loading="require('../../assets/shop-default.png')"
              :data-src="getImgUrl()"
              class="imgClass"
            />
          </div>
          <div class="shop-title">
            <p class="title text">{{ shopInfoObj.name }}</p>
            <div class="leaver-logo flexbox">
              <div
                v-for="(svgItem, svgIndex) in 5"
                :key="svgIndex"
                :class="[
                  svgIndex + 1 <= shopInfoObj.star && 'active-leaver',
                  'leaver-item',
                ]"
              >
                <svg-icon icon-class="leaver-svg"></svg-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="header-right flexbox">
          <div class="img-item imgFlex" @click="onChangeService">
            <img src="../../assets/service.png" />
          </div>
          <div class="svg-item">
            <languageCard />
          </div>
        </div>
      </div>
      <div class="count-bg"></div>
      <div class="home-page-count">
        <div class="count-boxers flexbox">
          <div class="count-list">
            <p class="money-value">
              <countTo
                :startVal="0"
                :endVal="homeInfoObj.unitAmount"
                :duration="1200"
                :decimals="2"
                :separator="','"
                :prefix="moneyIcon"
              ></countTo>
            </p>
            <p class="money-text flexbox">
              <svg-icon icon-class="total-sales"></svg-icon>
              <span>{{ language.accountLabel1 }}</span>
            </p>
          </div>
          <div class="count-list">
            <p class="money-value">
              <countTo
                :startVal="0"
                :endVal="homeInfoObj.flow"
                :duration="1200"
                :separator="','"
              ></countTo>
            </p>
            <p class="money-text flexbox">
              <svg-icon icon-class="total-flow"></svg-icon>
              <span>{{ language.accountLabel2 }}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="home-page-content">
        <div class="card-model-boxers">
          <div class="card-title">{{ language.cardLabel1 }}</div>
          <div class="card-list">
            <!-- 1 -->
            <div class="list-item">
              <div class="item-day">
                <div class="day-title flexbox">
                  <span class="text2">{{ language.dataCardTitle1 }}</span>
                  <span class="tags">{{ language.dataCardDayTags }}</span>
                </div>
                <div class="day-data flexbox">
                  <div class="left">
                    <countTo
                      :startVal="0"
                      :endVal="homeInfoObj.unitAmount"
                      :duration="1200"
                      :separator="','"
                      :decimals="2"
                      :prefix="moneyIcon"
                    ></countTo>
                  </div>
                  <div class="right flexbox">
                    <countTo
                      :startVal="0"
                      :endVal="homeInfoObj.unitAmountPercentage"
                      :duration="1200"
                      :separator="','"
                      :suffix="'%'"
                    ></countTo>
                    <span>
                      <svg-icon
                        icon-class="income-up"
                        v-if="homeInfoObj.unitAmountPercentageSymbol == '+'"
                      />
                      <svg-icon
                        icon-class="income-down"
                        class="income-down"
                        v-if="homeInfoObj.unitAmountPercentageSymbol == '-'"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div class="item-yesterday flexbox">
                <span class="text2">{{ language.dataYesterTags }}</span>
                <countTo
                  :startVal="0"
                  :endVal="homeInfoObj.yesterdayUnitAmount"
                  :duration="1200"
                  :decimals="2"
                  :separator="','"
                  :prefix="moneyIcon"
                ></countTo>
              </div>
            </div>
            <!-- 2 -->
            <div class="list-item">
              <div class="item-day">
                <div class="day-title flexbox">
                  <span class="text2">{{ language.dataCardTitle2 }}</span>
                  <span class="tags">{{ language.dataCardDayTags }}</span>
                </div>
                <div class="day-data flexbox">
                  <div class="left">
                    <countTo
                      :startVal="0"
                      :endVal="homeInfoObj.flow"
                      :duration="1200"
                      :separator="','"
                    ></countTo>
                  </div>
                  <div class="right flexbox">
                    <countTo
                      :startVal="0"
                      :endVal="homeInfoObj.flowPercentage"
                      :duration="1200"
                      :separator="','"
                      :suffix="'%'"
                    ></countTo>
                    <span>
                      <svg-icon
                        icon-class="income-up"
                        v-if="homeInfoObj.flowSymbol == '+'"
                      />
                      <svg-icon
                        icon-class="income-down"
                        class="income-down"
                        v-if="homeInfoObj.flowSymbol == '-'"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div class="item-yesterday flexbox">
                <span class="text2">{{ language.dataYesterTags }}</span>
                <countTo
                  :startVal="0"
                  :endVal="homeInfoObj.yesterdayFlow"
                  :duration="1200"
                  :separator="','"
                ></countTo>
              </div>
            </div>
            <!-- 3 -->
            <div class="list-item">
              <div class="item-day">
                <div class="day-title flexbox">
                  <span class="text2">{{ language.dataCardTitle3 }}</span>
                  <span class="tags">{{ language.dataCardDayTags }}</span>
                </div>
                <div class="day-data flexbox">
                  <div class="left">
                    <countTo
                      :startVal="0"
                      :endVal="homeInfoObj.order"
                      :duration="1200"
                      :separator="','"
                    ></countTo>
                  </div>
                  <div class="right flexbox">
                    <countTo
                      :startVal="0"
                      :endVal="homeInfoObj.orderPercentage"
                      :duration="1200"
                      :separator="','"
                      :suffix="'%'"
                    ></countTo>
                    <span>
                      <svg-icon
                        icon-class="income-up"
                        v-if="homeInfoObj.orderSymbol == '+'"
                      />
                      <svg-icon
                        icon-class="income-down"
                        class="income-down"
                        v-if="homeInfoObj.orderSymbol == '-'"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div class="item-yesterday flexbox">
                <span class="text2">{{ language.dataYesterTags }}</span>
                <countTo
                  :startVal="0"
                  :endVal="homeInfoObj.yesterdayOrder"
                  :duration="1200"
                  :separator="','"
                ></countTo>
              </div>
            </div>
            <!-- 4 -->
            <div class="list-item">
              <div class="item-day">
                <div class="day-title flexbox">
                  <span class="text2">{{ language.dataCardTitle4 }}</span>
                  <span class="tags">{{ language.dataCardDayTags }}</span>
                </div>
                <div class="day-data flexbox">
                  <div class="left">
                    <countTo
                      :startVal="0"
                      :endVal="homeInfoObj.waitSumAmount"
                      :duration="1200"
                      :separator="','"
                      :decimals="2"
                      :prefix="moneyIcon"
                    ></countTo>
                  </div>
                  <div class="right flexbox"></div>
                </div>
              </div>
              <div class="item-yesterday flexbox">
                <span class="text2">{{ language.dataYesterTags1 }}</span>
                <countTo
                  :startVal="0"
                  :endVal="homeInfoObj.waitCount"
                  :duration="1200"
                  :separator="','"
                ></countTo>
              </div>
            </div>
          </div>
        </div>
        <!-- echarts图片 -->
        <div class="card-echarts-boxers card">
          <div class="card-title">{{ language.cardLabel2 }}</div>
          <div class="echart-warper">
            <echarChartsCard></echarChartsCard>
          </div>
        </div>
        <div class="card-menus card">
          <div class="list-boxers">
            <div
              class="item-list"
              @click="onChangeMenus('/appAmountRechargeIndex')"
            >
              <div class="icon">
                <img src="../../assets/menusIcon2.png" />
              </div>
              <p class="text">{{ language.cardMenusLabel2 }}</p>
            </div>
            <div class="item-list" @click="onChangeMenus('/appWithdrawIndex')">
              <div class="icon">
                <img src="../../assets/menusIcon3.png" />
              </div>
              <p class="text">{{ language.cardMenusLabel3 }}</p>
            </div>
<!--            <div class="item-list" @click="onChangeMenus('/appInviteIndex')">-->
<!--              <div class="icon">-->
<!--                <img src="../../assets/menusIcon4.png" />-->
<!--              </div>-->
<!--              <p class="text">{{ language.cardMenusLabel4 }}</p>-->
<!--            </div>-->
            <div class="item-list" @click="onChangeMenus('/appProjectIndex')">
              <div class="icon">
                <img src="../../assets/menusIcon5.png" />
              </div>
              <p class="text">{{ language.cardMenusLabel5 }}</p>
            </div>
            <div class="item-list" @click="onChangeMenus('/appOrderIndex')">
              <div class="icon">
                <img src="../../assets/menusIcon6.png" />
              </div>
              <p class="text">{{ language.cardMenusLabel6 }}</p>
            </div>
            <div
              class="item-list"
              @click="onChangeMenus('/appStatisticsReportIndex')"
            >
              <div class="icon">
                <img src="../../assets/menusIcon7.png" />
              </div>
              <p class="text">{{ language.cardMenusLabel7 }}</p>
            </div>
            <div
                class="item-list"
                @click="onChangeMenus('tax')"
            >
              <div class="icon">
                <img src="../../assets/menusIcon1.png" />
              </div>
              <p class="text">{{ language.cardMenusLabel1 }}</p>
            </div>
          </div>
        </div>

        <div class="card-state-boxers">
          <div class="card-title">{{ language.cardLabel3 }}</div>
          <div class="state-list-boxers">
            <div class="state-list-item">
              <div class="state-content">
                <p>{{ language.cardStateLabel1 }}</p>
                <p>
                  <countTo
                    :startVal="0"
                    :endVal="shopInfoObj.balance"
                    :duration="1200"
                    :decimals="2"
                    :separator="','"
                    :prefix="moneyIcon"
                  ></countTo>
                </p>
              </div>
            </div>
            <div class="state-list-item">
              <div class="state-content">
                <p>{{ language.cardStateLabel2 }}</p>
                <p>
                  <countTo
                    :startVal="0"
                    :endVal="shopInfoObj.withdrawal"
                    :duration="1200"
                    :decimals="2"
                    :separator="','"
                    :prefix="moneyIcon"
                  ></countTo>
                </p>
              </div>
            </div>
            <div class="state-list-item">
              <div class="state-content">
                <p>{{ language.cardStateLabel3 }}</p>
                <p>
                  <countTo
                    :startVal="0"
                    :endVal="shopInfoObj.profit"
                    :duration="1200"
                    :decimals="2"
                    :separator="','"
                    :prefix="moneyIcon"
                  ></countTo>
                </p>
              </div>
            </div>
            <div class="state-list-item">
              <div class="state-content">
                <p>{{ language.cardStateLabel4 }}</p>
                <p>
                  <countTo
                    :startVal="0"
                    :endVal="shopInfoObj.commissionProfit"
                    :duration="1200"
                    :decimals="2"
                    :separator="','"
                    :prefix="moneyIcon"
                  ></countTo>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="card-sellHot-boxers">
          <div class="sellHot-title">{{ language.cardSellHotTitle }}</div>
          <div class="sellHot-list" v-if="sellHotList.length">
            <div
              class="list-item flexbox"
              v-for="(item, index) in sellHotList"
              :key="index"
            >
              <div class="left-img imgFlex">
                <img v-lazy="item.imageIcon" />
              </div>
              <div class="right-item">
                <div class="item-name text2">
                  {{ item.name }}
                </div>
                <div class="item-sales">
                  <span>{{ language.salesLabel }}</span>
                  <span>{{ item.number }}</span>
                </div>
                <div class="item-attributes">
                  <div
                    class="attributes-list flexbox"
                    v-for="(
                      child, childIndex
                    ) in item.productSkuAttributesValue"
                    :key="childIndex"
                  >
                    <span class="list-tag" v-if="child.productAttributes">
                      {{ child.productAttributes.name }}
                    </span>
                    <span class="list-value text2">{{
                      child.productAttributesValue.name
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <default-page v-else></default-page>
        </div>
      </div>
    </div>
    <div class="footer-page">
      <footerCard :key="count"></footerCard>
    </div>
    <template v-if="taxVisible">
      <van-popup v-model="taxVisible" class="tax-popup">
        <div class="popup-body">
          <van-icon name="cross" class="close-icon" @click="taxVisible=false"/>
          <div class="title">{{ language.taxPopupTitle }}</div>
          <div class="value">
            <countTo
              :startVal="0"
              :endVal="homeInfoObj.taxNeedAmount"
              :duration="1200"
              :decimals="2"
              :separator="','"
              :prefix="moneyIcon"
            ></countTo> 
          </div>
          <div class="options">
            <span @click="onChangeTax">{{ language.popupBtn }}</span>
          </div>
        </div>
      </van-popup>
    </template>
    <appServiceDialog
      v-if="serviceVisible"
      :visible="serviceVisible"
      :serviceObj="serviceData"
      @handlerCloseService="serviceVisible = false"
    >
    </appServiceDialog>
  </div>
</template>

<script>
import serviceApi from "@/api/service";
import appServiceDialog from "@/views/servicePage/service-page.vue";
import languageCard from "@/layout/language";
import footerCard from "@/layout/footerCard.vue";
import echarChartsCard from "./component/echart.vue";
import homeApi from "@/api/homeApi";
import imgPath from "@/utils/projectConfig.js";
export default {
  name: "appHomeIndex",
  data() {
    return {
      serviceVisible: false,
      language: this.$language(),
      imgPath: imgPath.imgUrl,
      thumbnail: this.$thumbnail,
      taxVisible: false,
      shopInfoObj: {},
      homeInfoObj: {
        unitAmount: 0,
        order: 0,
        flow: 0,
        waitSumAmount: 0,
        unitAmountPercentage: 100,
        orderPercentage: 100,
        flowPercentage: 100,
        yesterdayUnitAmount: 0,
        yesterdayFlow: 0,
        yesterdayOrder: 0,
        waitCount: 0,
      },
      count: 0,
      shopImg: "",
      stareLever: 4,
      moneyIcon: localStorage.getItem("moneyIcon"),
      sellHotList: [],
      serviceData:{}
    };
  },
  activated() {
    localStorage.setItem("moneyIcon", "$");
    this.count += 1;
    // if (this.$route.path === "/appOrderIndex" && this.count) {
    //   this.init();
    // } else {
    //   this.count += 1;
    // }
  },
  created() {
    this.$nextTick(() => {
      const seeHeight = window.innerHeight;
      document.getElementById("pageWarper").style["height"] = seeHeight + "px";
    });

    //
    localStorage.setItem("moneyIcon", "$");
    this.moneyIcon = localStorage.getItem("moneyIcon") || "$";
    this.count += 1;
  },
  mounted() {
    this.sellHotList.map((item) => {
      item.attributes.map((record) => {
        record.label = record.attributesLabel.name;
        record.value = record.attributesValue.name;
      });
    });
    this.onLoad();
  },
  methods: {
    async onChangeService() {
      const { code, data, message } = await serviceApi.getService({
        code: localStorage.getItem("code") ?? "",
      });
      if (code == 200) {
        this.serviceData = data;
        this.serviceVisible = true;
      }
    },
    getImgUrl(url) {
      if (this.shopInfoObj.image) {
        return this.imgPath + this.shopInfoObj.image + this.thumbnail;
      }
    },
    onLoad() {
      const p1 = homeApi.getHome_interface();
      const p2 = homeApi.getShopInfo_interface();
      const p3 = homeApi.skuLists_interface({size:10});
      this.getHomeInterfaceData([p1, p2, p3]);
    },
    onChangeMenus(key) {
      if (key === "tax") {
        this.taxVisible = true;
      } else {
        this.$router.push({ path: key });
      }
    },
    onChangeTax() {
      this.taxVisible = false;
      this.$router.push({ path: "/appAmountRechargeIndex" });
    },
    // 数据并发请求
    getHomeInterfaceData(port) {
      Promise.allSettled(port).then((res) => {
        console.log(res);
        if (res) {
          if (res[0].value && res[0].value.code == 200) {
            this.homeDataCall(res[0].value.data);
          }
          if (res[1].value && res[1].value.code == 200) {
            this.shopInfoCall(res[1].value.data);
          }
          if (res[2].value && res[2].value.code == 200) {
            this.skuDataList(res[2].value.data);
          }
        }
      });
    },
    homeDataCall(data) {
      data.unitAmount = Number((data.unitAmount / 100).toFixed(2));
      data.balance = Number((data.balance / 100).toFixed(2));
      data.yesterdayUnitAmount = Number(
        (data.yesterdayUnitAmount / 100).toFixed(2)
      );
      data.taxNeedAmount = Number((data.taxNeedAmount / 100).toFixed(2));
      data.waitSumAmount = Number((data.waitSumAmount / 100).toFixed(2));

      this.homeInfoObj = data;
    },
    shopInfoCall(data) {
      data.balance = Number((data.balance / 100).toFixed(2));
      data.recharge = Number((data.recharge / 100).toFixed(2));
      data.withdrawal = Number((data.withdrawal / 100).toFixed(2));
      data.profit = Number((data.profit / 100).toFixed(2));
      data.commissionProfit = Number((data.commissionProfit / 100).toFixed(2));
      this.shopInfoObj = data;
      if (Number(this.shopInfoObj.taxNeedAmount) > 0) this.taxVisible = true
    },
    skuDataList(data) {
      data.list.map((item) => {
        item.imageIconUrl = this.imgPath + item.image;
        item.imageIcon = this.imgPath + item.image + this.thumbnail;
        item.totalProfitAmount = (
          parseFloat(item.totalProfitAmount) / 100
        ).toFixed(2);
        item.totalPurchaseAmount = (
          parseFloat(item.totalPurchaseAmount) / 100
        ).toFixed(2);
        item.totalProfitAmount = (
          parseFloat(item.totalProfitAmount) / 100
        ).toFixed(2);
        item.unitAmount = (parseFloat(item.unitAmount) / 100).toFixed(2);
      });
      this.sellHotList = data.list;
    },
  },
  components: {
    languageCard,
    footerCard,
    echarChartsCard,
    appServiceDialog,
  },
};
</script>

<style lang="scss" scoped>
.appHomePage-warper {
  width: 100%;
  height: 100vh;
  background-color: #eff2f6;

  .home-page-boxers {
    width: 100%;
    height: calc(100% - 100px);
    overflow-y: scroll;
  }

  .home-page-header {
    height: 192px;
    background-color: $blur;
    justify-content: space-between;
    padding: 0 30px;

    .header-left {
      width: 70%;

      .user-avatar {
        width: 82px;
        height: 82px;
        border-radius: 50%;
        background-color: #eff2f6;
      }

      .shop-title {
        width: calc(100% - 82px);
        padding-left: 5%;

        .title {
          width: 95%;
          color: #fff;
          font-size: 32px;
          font-weight: 700;
          margin-bottom: 10px;
        }

        .leaver-logo {
          .leaver-item {
            color: #cdcdcd !important;
            margin-right: 6px;
          }

          .active-leaver {
            color: #ffd700 !important;
          }
        }
      }
    }

    .header-right {
      cursor: pointer;

      .img-item {
        width: 48px;
        height: 48px;
        margin-right: 30px;
      }
    }
  }

  .count-bg {
    background-color: $blur;
    width: 100%;
    padding: 30px 0;
    margin-top: -1px;
  }

  .home-page-count {
    width: 100%;
    padding: 0 30px;
    margin-top: -90px;
    margin-bottom: 30px;

    .count-boxers {
      padding: 30px 0;
      background-color: #fff;
      border-radius: 8px;
      justify-content: space-around;

      .count-list {
        flex: 1;
        font-size: 48px;
        font-weight: 700;
        color: $blur;
        line-height: 1.5;
        text-align: center;

        .money-text {
          margin-top: 20px;
          justify-content: center;

          svg {
            border-radius: 8px;
            overflow: hidden;
            opacity: 0.8;
            width: 30px !important;
            height: 30px !important;
            margin-right: 8px;
          }

          font-size: 24px;
          color: #999;
        }
      }
    }
  }

  .home-page-content {
    padding: 0 30px 30px 30px;

    .card-title {
      font-size: 28px;
      color: #000;
      font-weight: 700;
    }

    .card-model-boxers {
      background-color: #fff;
      border-radius: 8px;
      margin-bottom: 30px;
      padding: 30px 34px;
    }

    .card-list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      .list-item {
        width: 48%;
        margin-right: 4%;
        margin-top: 40px;
        background-color: #f7f8fb;
        border-radius: 4px;
        overflow: hidden;
        padding: 30px 20px;

        .item-day {
          border-bottom: 1px solid #ccc;

          .day-title {
            font-size: 24px;
            color: $color3;
            line-height: 1.5;
            font-weight: 500;
            justify-content: space-between;

            .tags {
              padding: 4px;
              border-radius: 4px;
              border: 1px solid #ccc;
              font-size: 18px;
              font-weight: 400;
              line-height: 1;
            }
          }

          .day-data {
            justify-content: space-between;
            padding: 30px 0;

            .left {
              width: 50%;
              font-size: 22px;
              color: $blur;
              font-weight: 700;
              text-align: left;
            }

            .right {
              width: 50%;
              justify-content: flex-end;
              span:nth-child(2) {
                margin-left: 8px;

                svg {
                  width: 32px !important;
                  height: 32px !important;
                  color: $blur;
                }

                .income-down {
                  color: #ff3646;
                }
              }
            }
          }
        }

        .item-yesterday {
          padding-top: 30px;
          justify-content: space-between;

          span:nth-child(1) {
            color: #999;
            font-size: 22px;
            line-height: 1;
            width: 50%;
          }

          span:nth-child(2) {
            width: 50%;
            color: $blur;
            font-size: 22px;
            line-height: 1;
            text-align: right;
          }
        }
      }

      .list-item:nth-child(2n) {
        margin-right: 0;
      }
    }
  }

  .card-echarts-boxers {
    width: 100%;

    .echart-warper {
      min-height: 510px;
      height: 510px;
    }
  }

  .card {
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 30px;
    padding: 24px 30px;
  }

  .card-menus {
    width: 100%;
    padding: 36px 0;

    .list-boxers {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;

      .item-list {
        width: calc(100% / 3);
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 40px;

        .icon {
          width: 60px;
          height: 60px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        p {
          margin-top: 10px;
          font-size: 24px;
          color: #000;
        }
      }

      .item-list:nth-child(4),
      .item-list:nth-child(5),
      .item-list:nth-child(6) {
        margin-bottom: 0;
      }
    }
  }

  .card-state-boxers {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;

    .card-title {
      font-size: 28px;
      color: #000;
      font-weight: 700;
      margin-bottom: 20px;
    }

    .state-list-boxers {
      display: flex;
      flex-wrap: wrap;

      .state-list-item {
        width: calc((100% - 30px) / 2);
        height: auto;
        margin-right: 30px;
        margin-bottom: 30px;
        border-radius: 8px;
        background-color: #f7f8fb;

        .state-content {
          width: 100%;
          height: 100%;
          padding: 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          p:nth-child(1) {
            font-size: 28px;
            color: $color3;
            text-align: left;
            display: inline-block;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            overflow: hidden;
            -webkit-box-orient: vertical;
            word-break: break-all;
          }

          p:nth-child(2) {
            font-size: 36px;
            font-weight: 500;
            color: $blur;
            text-align: right;
            padding-top: 52px;
          }
        }
      }

      .state-list-item:nth-child(2n) {
        margin-right: 0;
      }

      // .state-list-item:nth-child(1) {
      //   background-color: rgb(92, 157, 255);
      // }

      // .state-list-item:nth-child(2) {
      //   background-color: rgb(255, 128, 73);
      // }

      // .state-list-item:nth-child(3) {
      //   background-color: rgb(63, 188, 143);
      // }

      // .state-list-item:nth-child(4) {
      //   background-color: rgb(93, 106, 126);
      // }

      // .state-list-item:nth-child(5) {
      //   background-color: rgb(102, 94, 189);
      // }

      // .state-list-item:nth-child(6) {
      //   background-color: $blur;
      // }
    }
  }

  .card-sellHot-boxers {
    margin-top: 30px;

    .sellHot-title {
      font-size: 28px;
      color: #333;
      font-weight: 700;
    }

    .sellHot-list {
      margin-top: 26px;

      .list-item {
        padding: 20px;
        background-color: #fff;
        border-radius: 8px;
        margin-bottom: 30px;

        .left-img {
          width: 156px;
          height: 156px;
          border-radius: 6px;
        }

        .right-item {
          padding-left: 20px;
          width: calc(100% - 156px);

          .item-name {
            font-size: 28px;
            line-height: 1;
            color: #333;
          }

          .item-sales {
            margin-top: 10px;
            font-size: 24px;
            color: #999;
          }

          .item-attributes {
            .attributes-list {
              margin-top: 10px;

              .list-tag {
                padding: 6px;
                background-color: #409eff;
                color: #fff;
                font-size: 20px;
                border-radius: 4px;
                margin-right: 4px;
              }

              .list-value {
                color: #333;
                font-size: 20px;
                line-height: 1;
              }
            }
          }
        }
      }

      .list-item:last-child {
        margin-bottom: 0;
      }
    }
  }

  .tax-popup {
    border-radius: 8px;
    padding: 40px 30px;
    width: 420px;

    .popup-body {
      position: relative;
      .close-icon{
        position:absolute;
        font-size:40px;
        font-weight:500;
        right:-10px;
        top:-20px;
      }
      .title {
        font-size: 30px;
        font-weight: 600;
        color: $color3;
        line-height: 1.5;
      }

      .value {
        margin-top: 15px;
        text-align: center;
        color: $blur;
        font-size: 26px;
        line-height: 1.5;
        font-weight: 600;
      }

      .options {
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          display: inline-block;
          text-align: center;
          background: $blur;
          color: #fff;
          border-radius: 8px;
          font-size: 26px;
          width: 80%;
          padding: 20px 32px;
        }
      }
    }
  }
}
</style>
